
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo: { title: 'Nutrição Moderna' },

    extends: View,

    mixins: [
      LoadSections([
        'hero',
        'video',
        // 'what-make-me-lose',
        // 'the-solution',
        'steps',
        'about-me',
        'depositions',
        // 'value-anchor',
        // 'bonus',
        'plans',
        'no-values',
        // 'warranty',
        // 'product-reinforcement',
        // 'faq',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
